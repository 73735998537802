/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import ReactDOM from 'react-dom';
import './src/styles/global.css';

/* 
Because of Gatsby's server side rendering causing component styling issues with our application
we have decided to re-render every component instead of rehydrating. This solution replaces
a useEffect call on affected components, at the cost of a performance hit.
*/
export function replaceHydrateFunction() {
	return (element, container, callback) => {
		ReactDOM.render(element, container, callback);
	};
}
